export const base_path = "/api/v2";

export const apiPaths = {
  base_path,
  login_path: base_path + "/auth/login",
  register_user_path: base_path + "/auth/register",
  logout_path: base_path + "/auth/logout",
  forgot_pass_path: base_path + "/auth/reset-password",
  change_pass_path: base_path + "/auth/change-password",
  users_path: base_path + "/users",
  users_unblock_path: base_path + "/users/unblock",
  users_block_path: base_path + "/users/block",
  users_enable_path: base_path + "/users/enable",
  users_resend_invitation: base_path + "/users/resend-invitation",
  password_policy: base_path + "/users/password-policy",
  dep_path: base_path + "/departments",
  role_path: base_path + "/user_roles",
  prodtype_path: base_path + "/product_types",
  eqgrp_path: base_path + "/equipment_groups",
  import_path: base_path + "/import",
  ca_path: base_path + "/cleaning_agents",
  log_path: base_path + "/audit_logs",
  rpn_formula_path: base_path + "/rpn_formulas",
  rpn_cat_path: base_path + "/rpn_categories",
  var_path: base_path + "/variables",
  report_path: base_path + "/reports",
  formula_path: base_path + "/formulas",
  report_generation_path: base_path + "/reports/generate_report",

  // /api/v2/dashboard
  // /api/v2/plans/:planid/facilities/:facId/dashboard

  dashboard_path: base_path + "/dashboard",
  dashboard_mac_limit_tracker_path: base_path + "/dashboard/mac_limit_tracker",
  default_units_path: base_path + "/default_units",
  feedback_path: base_path + "/feedback",
  features_path: base_path + "/features",
  swab_location_path: base_path + "/swab_locations",
  residue_measure_path: base_path + "/residue_measures",
  log_report_path: base_path + "/audit_logs/last_report",
  cleaning_limit_policies_path: base_path + "/cleaning_limit_policies",
  approval_policies_path: base_path + "/approval_policies",
  department_path: base_path + "/departments",
  signatures_path: base_path + "/signatures",
  selection_criteria_path: base_path + "/selection-criteria",
  validation_schedule_path: base_path + "/validation-schedule",
  risk_control_path: base_path + "/risk-goal",
  validation_record_path: base_path + "/equipments/quick-validation",
  analytical_method_path: base_path + "/analytical-method",
  reset_password_policy_path: base_path + "/auth/reset-password-policy",
  allowed_domains: base_path + "/users/allowedDomains",
  activeDirectorySearch: `${base_path}/activeDirectory/search`,
  activeDirectorySettings: `${base_path}/activeDirectory/settings`,
  historicalStudy: `${base_path}/historical-study`
};
